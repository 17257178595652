@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom gradient background */
.bg-gradient-customOpacity {
  /* Gradient colors */
  /* background: linear-gradient(119deg, #ED4690 -2.11%, #52C 63.58%); */
  /* Opacity */
  opacity: 0.9;
  background: #EAEBF2;
}


.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.event-gradient{
  border-radius: 12px;
  background: linear-gradient(91deg, rgba(42, 53, 128, 0.90) 24.48%, rgba(190, 134, 127, 0.80) 55.67%, rgba(230, 168, 17, 0.70) 74.65%);
}

.event-organizer-opacity{
  border-radius: 12px;
background: linear-gradient(93deg, rgba(250, 169, 163, 0.80) 15.16%, #A470B6 55.01%, rgba(61, 55, 241, 0.80) 90.79%);
}

/* Add this CSS */
.slick-slider {
  position: relative;
  overflow: hidden;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2%;
  border-radius: 50%;
  opacity: 0.8;
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  z-index: 1;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover {
  /* background-color: rgba(255, 255, 255, 1); */
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.arrows {
  height: 20px;
  width: 20px;
  margin: 10px;
  color: black;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

/* Media Queries for responsiveness */
@media (max-width: 640px) {
  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }
}

