.skeleton-wrapper {
    transition: opacity 0.1s ease;
  }
  
  .skeleton-hidden {
    opacity: 0;
  }
  
  .event-row {
    opacity: 0;
    transition: opacity 0.5s ease; 
  }
  
  .event-row-visible {
    opacity: 1;
  }

  .event-list {
  opacity: 0;
  transition: opacity 0.01s ease; 
}

.event-list-visible {
  opacity: 1;
}
  

